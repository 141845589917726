import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Le pronon personnel" />
    <h3 className='underline-title'>Le pronon personnel</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Il<br />
        Le pronon personnel que tu connais depuis la maternelle<br />
        troisième personne au singulier évidemment<br />
        Comme toujours<br />
        il est au genre masculin<br />
      </p>
      <p>
        Il <br />
        C'est qui?<br />
        Il<br />
        C'est qui?<br />
        Lui c'est qui?<br />
        Lui c'est il<br />
      </p>
      <p>
        Lui que tu connais depuis le patelin<br />
        Lui que tu évites de rencontrer<br />
        Lui que tu fais inviter, attendre et que tu ne reçois jamais<br />
      </p>
      <p>
        Lui c'est il<br />
        Il c'est lui<br />
        Lui que tu aimes pourtant<br />
        et que tu repousses en même temps<br />
        Lui don't la présence gêne <br />
        Lui que tes oreilles ne captent le nom et le renom<br />
      </p>
      <p>
        Lui c'est il <br />
        Il c'est lui <br />
        Lui qui est la mauvaise conscience <br />
        Lui dont la franchise irrite<br />
        la débrouillardise agace <br />
        la solitude poétique enrage <br />
        Lui cette bête bicéphale énigmatique <br />
      </p>
      <p>
        Lui c'est il <br />
        Il c'est lui <br />
        Lui c'est l'idôle des autres <br />
        Lui c'est la peste noire<br />
        Lui c'est le prophète dans sa patrie <br />
      </p>
      <p>
        Lui c'est il<br />
        Il c'est lui<br />
        Lui c'est comme toi <br />
        Lui c'est ton frère <br />
        Lui c'est le fils de Dieu <br />
      </p>
      <p>
        Dieu ton Père Céleste <br />
        son Père Céleste<br />
        votre Père Céleste <br />
      </p>
      <p>
        Lui <br />
        Comme toi<br />
        Toi <br />
        Comme lui<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
